import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kenneth/Sites/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About Type Brigade`}</h1>
    <p>{`Type Brigade is Vancouver’s type speaker series. We organise about 6 events a year on typography, type design, lettering, and other subjects through the lens of letters. We find, if you speak about anything for long enough, it always comes back to type—all the more reason to speak about it sooner.`}</p>
    <p><a parentName="p" {...{
        "href": "https://patreon.com/typebrigade"
      }}>{`Our amazing patrons on Patreon`}</a>{` help make Type Brigade events possible. Back us on Patreon to receive special perks, like reserved seats at our free events.`}</p>
    <h2>{`Contact`}</h2>
    <ul>
      <li parentName="ul">{`Email `}<a parentName="li" {...{
          "href": "mailto:kenneth@typebrigade.com"
        }}>{`kenneth@typebrigade.com`}</a><br /></li>
      <li parentName="ul">{`Patreon `}<a parentName="li" {...{
          "href": "http://patreon.com/typebrigade"
        }}>{`@typebrigade`}</a></li>
      <li parentName="ul">{`Instagram `}<a parentName="li" {...{
          "href": "http://instagram.com/typebrigade"
        }}>{`@typebrigade`}</a><br /></li>
      <li parentName="ul">{`Twitter `}<a parentName="li" {...{
          "href": "http://twitter.com/typebrigade"
        }}>{`@typebrigade`}</a><br /></li>
    </ul>
    <h2>{`Team`}</h2>
    <p>{`All contributors and speakers hope to bring the type and design community in Vancouver a little closer together.`}</p>
    <ul>
      <li parentName="ul">
        <h3 parentName="li">{`Kenneth Ormandy`}</h3>
        <img parentName="li" {...{
          "width": 100,
          "src": "https://unavatar.now.sh/kennethormandy.com",
          "alt": "Kenneth Ormandy’s profile picture"
        }}></img>
        <p parentName="li"><em parentName="p">{`Organiser`}</em></p>
        <p parentName="li">{`Kenneth is a typographic software developer and type designer.`}</p>
        <p parentName="li"><a parentName="p" {...{
            "href": "https://kennethormandy.com"
          }}>{`kennethormandy.com`}</a></p>
      </li>
      <li parentName="ul">
        <h3 parentName="li">{`Alanna Munro`}</h3>
        <img parentName="li" {...{
          "width": 100,
          "src": "https://unavatar.now.sh/alannamunro.com",
          "alt": "Alanna Munro’s profile picture"
        }}></img>
        <p parentName="li"><em parentName="p">{`Organiser, Lettering`}</em></p>
        <p parentName="li">{`Alanna is a freelance interaction designer, typographer, letter-maker and code explorer.`}</p>
        <p parentName="li"><a parentName="p" {...{
            "href": "http://alannamunro.com"
          }}>{`alannamunro.com`}</a></p>
      </li>
      <li parentName="ul">
        <h3 parentName="li">{`Nancy Wu`}</h3>
        <img parentName="li" {...{
          "width": 100,
          "src": "https://unavatar.now.sh/nancywudesign.com",
          "alt": "Nancy Wu’s profile picture"
        }}></img>
        <p parentName="li"><em parentName="p">{`Assistant Organiser`}</em></p>
        <p parentName="li">{`Nancy Wu is a graphic designer specializing in brand identity, idea generation and art direction.`}</p>
        <p parentName="li"><a parentName="p" {...{
            "href": "http://nancywudesign.com/"
          }}>{`nancywudesign.com`}</a></p>
      </li>
      <li parentName="ul">
        <h3 parentName="li">{`Joshua Mann`}</h3>
        <p parentName="li"><em parentName="p">{`Assistant Organiser`}</em></p>
        <img parentName="li" {...{
          "width": 100,
          "src": "https://unavatar.now.sh/joshuamann.ca",
          "alt": "Joshua Mann’s profile picture"
        }}></img>
        <p parentName="li"><a parentName="p" {...{
            "href": "https://http://joshuamann.ca/"
          }}>{`joshuamann.ca`}</a></p>
      </li>
    </ul>
    <h2>{`Friends & Collaborators`}</h2>
    <ul>
      <li parentName="ul">{`Shelley Gruendler`}</li>
      <li parentName="ul">{`Cody Jones`}</li>
      <li parentName="ul">{`Andrea Husky`}</li>
      <li parentName="ul">{`Riley Cran`}</li>
      <li parentName="ul">{`Diane Huynh`}</li>
      <li parentName="ul">{`Ross Milne`}</li>
    </ul>
    <p>{`…and many more! We are working on revising this page as part of our new site.`}</p>
    {
      /*
      Dr. Shelley Gruendler started the original incarnation of this meetup as the Vancouver Typography Meetup Group. She’s a typographer, designer, and educator, in addition to being the founding director of [Type Camp](http://typecamp.org).
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      